import React from 'react';

import {theme, styled} from '@styles';
import {SvgIconProps} from '.';

const StyledTwitterIcon = styled.svg.withConfig({
  componentId: 'twitterIcon'
})<SvgIconProps>`
  margin: ${({margin}) => (margin ? margin : null)};
  & path {
    fill: ${({fill}) => (fill ? fill : theme.ctaFg)};
  }
`;

export default function TwitterIcon({
  fill,
  ariaHidden = true,
  margin,
}: SvgIconProps): JSX.Element {
  return (
    <StyledTwitterIcon
      width="30"
      height="30"
      viewBox="0 0 30 30"
      aria-hidden={ariaHidden}
      margin={margin}
      fill={fill}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M22.5439583 12.6048904c.2195834 4.8263014-3.4291666 10.2073973-9.88875 10.2073973-1.9645833 0-3.7933333-.5683562-5.33291663-1.5419178 1.84541666.2145205 3.68791663-.290137 5.15041663-1.4210959-1.5220833-.0275343-2.8070833-1.0195891-3.2495833-2.3827398.545.1027398 1.08125.0727398 1.5708333-.0583561-1.6733333-.3320548-2.82791663-1.8184932-2.79041663-3.4080822.46916666.2568493 1.00583333.4113699 1.57625.4286301-1.54958334-1.0208219-1.98833334-3.0386301-1.07666667-4.58095888 1.7154167 2.07616438 4.27875 3.44219178 7.1704167 3.58561648-.5070834-2.1468494 1.14375-4.21356168 3.3891666-4.21356168 1.0008334 0 1.905.41630137 2.5391667 1.08328768.7925-.1536986 1.5370833-.43931508 2.2091667-.83260275-.2595834.80136985-.81125 1.47410955-1.5295834 1.89821915.70375-.0826027 1.3741667-.2671233 1.9983334-.54-.46625.6883562-1.05625 1.2924658-1.7358334 1.7761644M15.0002083.35954794C6.71604167.35954794.00020833 6.9829726.00020833 15.1540685c0 8.1710959 6.71583334 14.7945205 14.99999997 14.7945205 8.2841667 0 15-6.6234246 15-14.7945205 0-8.1710959-6.7158333-14.79452056-15-14.79452056" />
      </g>
    </StyledTwitterIcon>
  );
}
